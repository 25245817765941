moduleName = 'Frontend';

if (document.getElementById('Authorize-module') != undefined) {
	moduleName = 'Authorize';
} else if (document.getElementById('Backend-module') != undefined) {
	moduleName = 'Backend';
}

Promise.all([import(`Modules/${moduleName.toLowerCase()}/zero/zero.jsx`)]).then(([Module]) => {
	const Component = Module[`${moduleName}Module`];

	ReactDOM.render(<Component />, document.getElementById(`${moduleName}-module`));
});
